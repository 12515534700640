import React from "react";

export default function BGSpinnerOverlay({isActive}) {
    function spinner() {
        if (isActive) {
            return
        }

        return ''
    }

    let overlay = (
        <div className={'d-flex h-100 w-100 top-0 justify-content-center align-items-center position-absolute'} style={{zIndex: 1999}}>
            <div className={'spinner-border text-primary'} role="status">
                <span className={"visually-hidden"}>Laddar...</span>
            </div>
        </div>
    )

    function renderOverlay() {
        if (isActive) {
            return overlay
        }

        return ''
    }

    return renderOverlay()
}
